import { ACCOUNT_TYPES, OPERATION_USER_POINT, STATUS_POINT, STAFF_ROLES, DEFAULT_CENTER_MAP } from "@/constants";
import routesNames from "@/router/routesNames";
import moment from "moment";
import QRCode from "qrcode";
import {notify} from "@kyvg/vue3-notification";


export const handleRedirect = (user: any, login: boolean) => {
  switch (user?.accountTypes?.cd) {
    case ACCOUNT_TYPES.STUDENT:
      return !login ? routesNames.student[0] : routesNames.student[1];
    case ACCOUNT_TYPES.STORE_ADMIN:
    case ACCOUNT_TYPES.STORE_USER:
      return !login ? routesNames.store[0] : routesNames.store[1];
    case ACCOUNT_TYPES.UNIVERSITY_ADMIN:
    case ACCOUNT_TYPES.UNIVERSITY_USER:
      return !login ? routesNames.school[0] : routesNames.school[1];
    case ACCOUNT_TYPES.SYSTEM_ADMIN:
      return !login ? routesNames.system[0] : routesNames.system[1];
    default:
      return routesNames.student[0];
  }
};

export const currencyFormatted = (num: any) => {
  return !!num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : 0;
};

function isValidGoogleMapsUrl(url: string) {
  if (!url) return;
  const regex = /^(https?:\/\/)?(www\.)?google\.com\/maps\/.*$/;
  return regex.test(url);
}

export const getLatLongFromUrl = (url: string) => {
  if (!isValidGoogleMapsUrl(url)) return;
  const longlat = /\/\@(.*),(.*),/.exec(url);
  return {
    latitude: longlat?.[1],
    longitude : longlat?.[2],
  }
};

export const groupDataPoint = (arr: any) => {
  if (!arr?.length) return [];
  const result = [...arr].reduce((r: any, item: any) => {
    const convertTime = moment(item.pointFuyobi).format("yyyy/MM");
    const key =
      convertTime +
      "_" +
      (item.oparationUser?.name
        ? OPERATION_USER_POINT.USER
        : OPERATION_USER_POINT.SYSTEM);
    if (!r?.[key]) {
      r[key] = {
        time: moment(item.pointFuyobi).format("yyyy/MM/DD HH:mm"),
        fuyoPoint: item.fuyoPoint,
        totalPoint: item.fuyoPoint,
        status: STATUS_POINT[item.status],
        user: item.oparationUser?.name
          ? OPERATION_USER_POINT.USER
          : OPERATION_USER_POINT.SYSTEM,
        totalUser: 1,
      };
    } else {
      r[key] = {
        ...r[key],
        totalPoint: item.fuyoPoint + r[key].totalPoint,
        totalUser: r[key].totalUser + 1,
      };
    }
    return r;
  }, {});
  return result;
};

export const DateOfStatus = (status: any, date: string, fieldStatus: any) => {
  const formattedDate = moment(date).format("yyyy/MM/DD HH:mm");
  if (status === fieldStatus) {
    return (date || moment(date).isValid()) ? formattedDate : '';
  }
};

export const formatCSVData = (csvData: any, dataMapping: any, isStore = false) => {
  return csvData.map((record: any) => {
    const formattedData: any = {};
    for (const key in dataMapping) {
      if (key === 'roleUser') {
        const isAdmin = record[dataMapping[key]] === STAFF_ROLES.ADMIN;
        formattedData[key] = isStore ? (isAdmin ? ACCOUNT_TYPES.STORE_ADMIN : ACCOUNT_TYPES.STORE_USER) : (isAdmin ? ACCOUNT_TYPES.UNIVERSITY_ADMIN : ACCOUNT_TYPES.UNIVERSITY_USER);
      } else {
        formattedData[key] = record[dataMapping[key]];
      }
    }
    return formattedData;
  });
};

export const downloadQRCode = (id: number, name: string = '') => {
  const url = process.env.VUE_APP_URL + `/student/use-point/${id}`;
  const qrcodeCanvas = document.createElement("canvas");
  const ctx = qrcodeCanvas.getContext("2d");
  if (!ctx) {
    return;
  }

  QRCode.toCanvas(qrcodeCanvas, url, {
    width: 200,
    margin: 6,
  }, (error) => {
    if (error) {
      console.error(error);
    } else {
      ctx.font = "20px Arial";
      const canvasWidth = qrcodeCanvas.width;
      const textWidth = ctx.measureText(name).width;
      const x = (canvasWidth - textWidth) / 2;
      ctx?.fillText(name, x, qrcodeCanvas.height - 5);
      const url = qrcodeCanvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = url;
      link.download = name || "qrcode.png";
      link.click();
    }
  });
};

export const toastError = (error: string, title: string = 'エラー') => {
  notify({
    type: "error",
    title: title,
    text: error || '',
  })
}

export const getCoordinates = async (formStore: any) => {
  return true;
  const addressSearch = (formStore.pref || "") + (formStore.city || "") + (formStore.addr || "") + (formStore?.bldg || "");
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(addressSearch)}&key=${process.env.VUE_APP_MAP_GEO_API_KEY}`);
  const data = await response.json();
  if (data.results && data.results.length > 0) {
    formStore.latitude = data.results[0]?.geometry?.location?.lat || "";
    formStore.longitude = data.results[0]?.geometry?.location?.lng || "";
    return data;
  } else {
    console.error("No results found for the given address.");
    toastError('入力された住所は存在しません。');
    return;
  }
}

/**
 * Retrieves the geographical coordinates (latitude and longitude) for a given address.
 *
 * @param {string} addressSearch - The address string to search for.
 * @returns {Promise<{ lat: string, lng: string }>} A promise that resolves to an object containing the latitude and longitude of the address.
 * If the address is not found, it returns an object DEFAULT_CENTER_MAP.
 *
 * @throws Will log an error and display a toast error message if the address is not found.
 */
export const getCoordinatesByAddress = async (addressSearch: string) => {
  if (!addressSearch) return DEFAULT_CENTER_MAP;
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(addressSearch)}&key=${process.env.VUE_APP_MAP_API_KEY}`);
  const data = await response.json();
  if (!data.results) {
    console.error("No results found for the given address.");
    toastError('入力された住所は存在しません。');
    return DEFAULT_CENTER_MAP;
  }
  return {
    lat: data.results[0]?.geometry?.location?.lat || "",
    lng: data.results[0]?.geometry?.location?.lng || "",
  }
}

export const calculatePointKigen = (pointFuyobi: any, monthsToAdd: number) => {
  let newPointKigen;
  if (pointFuyobi.date() === 1) {
    newPointKigen = pointFuyobi
      .clone()
      .add(monthsToAdd - 1, 'months')
      .endOf('month');
  } else if (pointFuyobi.isSame(pointFuyobi.clone().endOf('month'), 'day')) {
    newPointKigen = pointFuyobi
      .clone()
      .add(monthsToAdd, 'months')
      .endOf('month');
  } else {
    newPointKigen = pointFuyobi
      .clone()
      .add(monthsToAdd, 'months');
  }

  return newPointKigen;
}

export const handleNumberOnly = (value: any) => {
  return value.replace(/\D/g, '');
}
